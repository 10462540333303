import React from 'react'

function ElearningDescription() {
    return (
        <div style={{fontFamily:'nunito', fontSize:'3vh'}}>
            Mindenképpen ezt ajánljuk, ha:
                <ul>
                    <li>Már holnap szeretnél kezdeni</li>
                    <li>szeretsz önállóan tanulni</li>
                    <li>kevés a szabadidőd</li>
                    <li>rendszertelen a munkabeosztásod</li>
                    <li>saját ütemben szeretnél haladni</li>
                    <li>nem akarsz utazgatni</li>
                    <li>pár nap alatt végezni szeretnél</li>
                    <li>külföldön dolgozol.</li>
                </ul>
                Akkor ez az oktatási forma, a leginkább megfelelő Neked!<br/>
                <b>Gyors, olcsó és hatékony!</b>
        </div>
    )
}

export default ElearningDescription;
