import React, { useState, useEffect } from 'react'
import { Card, CardContent, Collapse, IconButton, makeStyles} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typical from 'react-typical';
import { Link as Scroll} from 'react-scroll';
import Navbar from './Navbar';

const useStyles = makeStyles((theme) => ({
    root:{
        display: 'flex',
        justifyContent: 'center',
        alignItems:'center',
        height: '100vh',
        fontFamily: 'Nunito',
        textAlign: 'center',
        fontSize:'3.5vh'
    },
    cards: {
        [theme.breakpoints.down('md')]:{
            flexDirection:'column'
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems:'center',
        gap: '10vh',
    },
    appbar:{
        background:'none',
    },
    appbarWrapper:{
        width:'80%',
        margin: '0 auto',
    },
    title:{
        color: '#000',
        fontSize: '4vh',
        fontFamily:'Nunito'
    },
    menuItems:{
        fontFamily: 'Nunito',
        fontSize: '1rem',
        color:'#000',
        fontWeight:'bolder',
        cursor:'pointer',
        marginRight:'2vh',
        marginLeft: '2vh',
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        width:'15vh',
    },
    slogens:{
        fontSize: '3vh',
        color:'black'
    },
    moreToKnow:{
        fontSize:'2.0vh'
    },
    card:{
        background:'rgba(242, 242, 242, 0.6)',
        borderRadius:'4vh',
        width:'50vh',
        height:'50vh'
    },
    warning:{
        fontWeight:'bolder',
        fontSize:'1.5vh',
        fontFamily:'Nunito'
    },
    svg:{
        maxWidth:'50vh',
        maxHeight:'50vh'
    }
}));

function Header() {
    const classes = useStyles();
    const [checked, setChecked] = useState(false);
    useEffect(() => {
        setChecked(true);
    }, []);
    const name = 'Zebra Autósiskola';
    const steps = ['Színvonalas oktatás', 2000, 'Kedvezmények', 2000, 'E-learning tanfolyam lehetőség', 2000, 'Képzett oktatók', 2000, 'Barátságos légkör', 2000, 'Rugalmas órarend', 2000]
    return (
        <>
            <Navbar/>
            <div className={classes.root} id="header">
                <div className={classes.cards}>
                <Card className={classes.card}>
                    <CardContent>
                        <h1 className={classes.title}>
                            Üdvözlünk a {name} weboldalán!
                        </h1>
                        <Typical className={classes.slogens}
                                steps={steps}
                                loop={Infinity}
                                wrapper="h1"
                        />
                        <Collapse in={checked} {...(checked) ? {timeout: 5000}: {}} >
                            <h1 className={classes.moreToKnow}>
                                Aktuális híreink!
                            </h1>
                            <Scroll to="news" smooth={true}>
                                <IconButton>
                                    <ExpandMoreIcon style={{fontSize:'8vh'}}/>
                                </IconButton>
                            </Scroll>
                        </Collapse>
                    </CardContent>
                </Card>
                </div>
            </div>
        </>
    )
}

export default Header;
