import React from 'react'
import { Card, CardContent, makeStyles, Typography } from '@material-ui/core'
import ScrollAnimation from 'react-animate-on-scroll';
import GroupIcon from '@material-ui/icons/Group';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import DiscountCard from './DiscountCard';

const useStyles = makeStyles((theme) => ({
    root:{
        minHeight:"100vh",
        margin: '50px 0 0 0',
        backgroundSize:'100% 100vh'
    },
    additionalInfo:{
        fontFamily:'Nunito',
        fontSize:'2vh'
    },
    description:{
        fontFamily: 'Nunito',
        fontSize: '3vh',
        color: '#000',
    },
    cards: {
        [theme.breakpoints.down('md')]:{
            flexDirection:'column'
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems:'center',
        gap: '10vh',
    },
    cardContent:{
        fontSize:'3vh',
        fontFamily: 'Nunito',
        fontWeight:'bolder'
    },
    requirements:{
        display: 'flex',
        justifyContent: 'center',
        alignItems:'center',
        fontSize:'3vh',
        fontFamily: 'Nunito',
        fontWeight:'bolder',
        margin:'3vh',
    },
    requirementsCard:{
        background:'none'
    },
    sectionTitle:{
        fontSize: '6vh',
        fontFamily: 'Nunito',
        fontWeight:'bolder',
        textAlign:'center'
    },
    icon:{
        fontSize:'30vh'
    }
}));

function Discount() {
    const classes = useStyles();
    return (
        <div id="discounts" className={classes.root}>
            <ScrollAnimation animateIn={'flipInY'} animateOnce={true}><h1 className={classes.sectionTitle}>Különleges ajánlataink</h1></ScrollAnimation>
            <div className={classes.cards}>
                <DiscountCard title={'Kártya'} imagePath={process.env.PUBLIC_URL + 'assets/giftcard.svg'} animationDirection='bounceInLeft' priceTable={<div className={classes.icon}><p className={classes.description}>Amennyiben birtokodba kerül egy általunk, <br/>a Zebra Autósiskola pecsétjével ellátott, kedvezményre jogosító kártya,<br/> akkor az azon feltűntetett összeg erejéig vehető igénybe.</p></div>}/>
                <DiscountCard title={'Páros kedvezmény'} imagePath={process.env.PUBLIC_URL + 'assets/pair.svg'} animationDirection='bounceInRight' priceTable={<div className={classes.icon}><ul className={classes.description}><li>Ha testvérrel, baráttal, barátnővel, szülővel, nagypapával, nagymamával stb. jössz: <b>5.000Ft</b></li><li>Ballagási, születésnapi, igazolt sportolói kedvezmény: <b>3.000Ft</b></li></ul></div>}/>
            </div>
            <div className={classes.requirements}>
                <ScrollAnimation animateIn={'wobble'} animateOnce={true} >
                    <Card className={classes.requirementsCard}>
                        <CardContent>
                        <Typography variant="body2" color="textSecondary" component="p" className={classes.cardContent}>
                            A kedvezmények nem vonhatóak össze!
                        </Typography>
                        </CardContent>
                    </Card>
                </ScrollAnimation>
            </div>
        </div>
    )
}

export default Discount;
