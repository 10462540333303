import { Divider } from '@material-ui/core';
import React from 'react'
import Contact from './Contact';
import Discount from './Discount';
import Footer from './Footer';
import HomePage from './HomePage';
import Offerings from './Offerings';
import Prices from './Prices';
import Teachers from './Teachers';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ScrollToTop  from "react-scroll-up";
import FacebookFeed from './FacebookFeed';
import FacebookContainer from './FacebookContainer';

function MainPage() {
    return (
        <>
            <HomePage/>
            <FacebookContainer/>
            <ScrollToTop showUnder={160}>
                <span><ArrowUpwardIcon style={{fontSize:'8vh'}}/></span>
            </ScrollToTop>
            <Offerings/>
            <Prices/>
            <Teachers/>
            <Discount/>
            <Contact/>
        </>
    )
}

export default MainPage;
