import React from 'react';
import {makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faYoutube,
    faFacebook,
    faTwitter,
    faInstagram
  } from "@fortawesome/free-brands-svg-icons";

const useStyles = makeStyles((theme) => ({
    socialContainer: {
        background: '#eee',
        padding: '2vh 4vh',
    },
    youtube:{
        color: '#eb3223',
        margin: '0 1rem',
        display: 'inline-block'
    },
    facebook:{
        color: '#4968ad',
        margin: '0 1rem',
        display: 'inline-block'
    },
    instagram:{
        color: 'black',
        margin: '0 1rem',
        display: 'inline-block'
    },
}));

function SocialMedia() {
    const classes = useStyles();
    return (
        <div className="socialContainer">
            <a href="https://www.youtube.com/"
            className={classes.youtube} target="__blank">
                <FontAwesomeIcon icon={faYoutube} size="1x" />
            </a>
            <a href="https://www.facebook.com/Zebra-aut%C3%B3siskola-102771398605350"
            className={classes.facebook} target="__blank">
                <FontAwesomeIcon icon={faFacebook} size="1x" />
            </a>
            <a href="https://www.instagram.com/"
            className={classes.instagram} target="__blank">
                <FontAwesomeIcon icon={faInstagram} size="1x" />
            </a>  
        </div>
    )
}

export default SocialMedia;
