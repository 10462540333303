import { CssBaseline, makeStyles } from '@material-ui/core'
import React from 'react'
import Header from './Header';

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100vh',
        backgroundImage: `url(${process.env.PUBLIC_URL + 'assets/home-background.svg'})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize:'100% 100vh',
    },
}));

function HomePage() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <CssBaseline/>
            <Header/>      
        </div>
    )
}

export default HomePage
