import React from 'react'
import {makeStyles } from '@material-ui/core'
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import SocialMedia from './SocialMedia';

const useStyles = makeStyles((theme) => ({
    footer: {
        padding: theme.spacing(3, 2),
        marginTop: 'auto',
        backgroundColor:
          theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
        fontFamily:'Nunito',

    },
    designer:{
        float:'right',
        fontFamily:'Nunito',
        fontSize:'1vh'
    },
    zebra:{
        fontFamily:'Nunito',
        fontWeight:'bolder'
    },
    copyright:{
        fontFamily:'Nunito',
        fontSize:'1vh'  
    },
    documents: {
      fontSize:'0.5vh',
      textDecoration:'none'
    }
}));

function Copyright({classes}) {
    return (
      <Typography variant="body2" color="textSecondary" className={classes.copyright}>
        {'Copyright © '}
        <Link color="inherit" href="https://zebraautosiskola.hu">
          Zebra autósiskola
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

function Footer() {
    const classes = useStyles();
    return (
        <footer className={classes.footer}>
            <Container maxWidth="sm">
            <Typography variant="body1" className={classes.zebra}>Zebra autósiskola</Typography>
            <Typography variant="body1" className={classes.designer}>
                <span>Designed by Tibor Illés</span>
            </Typography>
            <SocialMedia/>
            <Copyright classes={classes} />
            </Container>
        </footer>
    )
}

export default Footer;
