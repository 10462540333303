import React from 'react'
import { Button, Card, CardContent, CardMedia, Divider, makeStyles, Typography } from '@material-ui/core'
import PriceCard from './PriceCard';
import ClassRoomPriceTable from './ClassRoomPriceTable';
import ScrollAnimation from 'react-animate-on-scroll';

const useStyles = makeStyles((theme) => ({
    cards: {
        [theme.breakpoints.down('md')]:{
            flexDirection:'column'
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems:'center',
        flexWrap:'wrap',
        gap: '10vh',

    },
    root:{
        minHeight:"100vh",
        backgroundSize:'100% 100vh'
    },
    rootDiscount: {
        width: '50vh',
        height: '70vh',
        background: 'none',
        fontFamily:'nunito',
        fontSize:'2.5vh',
      },
      cardTitle:{
          fontFamily: 'Nunito',
          fontWeight: 'bold',
          fontSize: '4vh',
          textAlign:'center',
      },
      cardContent:{
        fontFamily: 'Nunito',
        fontSize: '1.0vh',
        fontWeight: 'bold',
        color: '#fff',
        
      },
    sectionTitle:{
        fontSize: '5vh',
        fontFamily: 'Nunito',
        fontWeight:'bolder',
        textAlign:'center',
    },
    additionalCosts:{
        textAlign: 'center',
        fontSize:'3vh',
        fontFamily:'nunito'
    },
    media:{
        height:'40vh'
        
    }
}));

function Prices() {
    const classes = useStyles();
    return (
        <>
        <div className={classes.root} id="prices">
            <ScrollAnimation animateIn={'flipInY'} animateOnce={true} >
                <h1 className={classes.sectionTitle}>Elméleti Képzési árak</h1>
            </ScrollAnimation>
            <div className={classes.cards}>
                <PriceCard title={'Tantermi'} animationDirection='bounceInLeft' priceTable={<ClassRoomPriceTable 
                                                      schedule="Előre egyeztetett időpontokban"
                                                      hour="28 óra"
                                                      price="60.000,-Ft (4.600.,-Ft is benne van az árban!), de csekkold a kedvezményeket!"
                                                      />}/>
                <PriceCard title={'E-learning I'} animationDirection='wobble' priceTable={<ClassRoomPriceTable
                                                        schedule="180 nap - 75 óra"
                                                        hour="28 óra"
                                                        price="50.000,-Ft (4.600.,-Ft is benne van az árban!)"
                />}/>
                <PriceCard title={'E-learning II'} animationDirection='wobble' priceTable={<ClassRoomPriceTable
                                                        schedule="180 nap - 75 óra + Eü. ismeretek"
                                                        hour="28 óra"
                                                        price="59.000,-Ft (4.600.,-Ft is benne van az árban!)"
                />}/>
                <PriceCard title={'E-learning III'} animationDirection='bounceInRight' priceTable={<ClassRoomPriceTable
                                                        schedule="360 nap - 75 óra + Eü. ismeretek"
                                                        hour="28 óra"
                                                        price="65.000,-Ft (4.600.,-Ft is benne van az árban!)"
                />}/>
            </div>
            <h4 className={classes.additionalCosts}>
                Elméleti vizsgadíja: 4600.,-Ft<br/>
                Közúti elsősegély tanfolyam e-learning tananyag: 13.000,-Ft 
            </h4>
            <ScrollAnimation animateIn={'flipInY'} animateOnce={true} >
                <h1 className={classes.sectionTitle}>Gyakorlati Képzési árak</h1>
            </ScrollAnimation>
            <div className={classes.cards}>
                <PriceCard title={'Manuális váltóval'} animationDirection='bounceInLeft' priceTable={<ClassRoomPriceTable
                                                            schedule="29 gyakorlati + 1 vizsgaóra"
                                                            hour="29 óra és 580km"
                                                            price="9.000,-Ft/óra"
                                                            
                    />}/>
                <PriceCard title={'Automata váltóval'} animationDirection='bounceInRight' priceTable={<ClassRoomPriceTable
                                                            schedule="29 gyakorlati + 1 vizsgaóra"
                                                            hour="29 óra és 580km"
                                                            price="9.000,-Ft/óra"
                />}/>  
            </div>
            <h4 className={classes.additionalCosts}>
                Forgalmi vizsgadíj: 11.000,-Ft
            </h4>
        </div>
        <ScrollAnimation animateIn='wobble' animateOnce={true} style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                <Card className={classes.rootDiscount}>
                    <CardMedia 
                        className={classes.media}
                        image={process.env.PUBLIC_URL + 'assets/saving.svg'}
                    />
                    <CardContent>
                        <Typography 
                        gutterBottom 
                        variant="h5" 
                        component="h1"
                        className={classes.cardTitle}>
                        {'Állami támogatás'}
                        </Typography>
                        <b>25.000 forintot visszaigényelhetsz a tanfolyam és vizsga árából 20 éves korig!</b>
                        <div style={{display:'flex', alignItems:'right', float:'right', marginTop:'5vh'}}>
                            <Button type="submit" variant="contained" style={{background:'none', fontFamily:'nunito', fontSize:'2.5vh', fontWeight:'bold'}}>
                                    <a href="https://folyositas.tcs.allamkincstar.gov.hu/t%C3%A1j%C3%A9koztat%C3%B3k/28-a-k%C3%B6zleked%C3%A9si-alapismeretek-tanfolyam-%C3%A9s-vizsga-d%C3%ADj%C3%A1hoz-ny%C3%BAjtott-t%C3%A1mogat%C3%A1s.html" style={{textDecoration:'none'}} target="__blank">
                                        Érdekel
                                    </a>
                            </Button>
                        </div>
                    </CardContent>
                </Card>
            </ScrollAnimation>
        </>
    )
}

export default Prices;
