import {
    AppBar,
    Chip,
    Toolbar,
    makeStyles,
    IconButton,
    Drawer,
    MenuItem,
    Menu,
    Fade,
    Button,
    Divider,
    Slide
  } from "@material-ui/core";
  import useScrollTrigger from '@material-ui/core/useScrollTrigger';
  import { Link as Scroll} from 'react-scroll';
  import MenuIcon from "@material-ui/icons/Menu";
  import React, { useState, useEffect } from "react";
  import CloseIcon from '@material-ui/icons/Close';
  import PropTypes from 'prop-types';

  function HideOnScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({ target: window ? window() : undefined });
  
    return (
      <Slide appear={false} direction="down" in={!trigger}>
        {children}
      </Slide>
    );
  }
  
  HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
  };


  const useStyles = makeStyles((theme) => ({
    appBar:{
        backgroundColor:'black',
    },
    header: {
      paddingRight: "79px",
      paddingLeft: "118px",
      "@media (max-width: 900px)": {
        paddingLeft: 0,
      },
    },
    toolbar: {
      display: "flex",
      justifyContent: "space-between",
    },
    menuItems:{
        fontFamily: 'Nunito',
        fontSize: '1.5vh',
        color:'white',
        fontWeight:'bolder',
        cursor:'pointer',
        marginRight:'2vh',
        marginLeft: '2vh',
        width:'20vh',
        background:'none',
        '&:hover':{
          backgroundColor:'white',
          color:'black'
        }
        
    },
    drawerButton:{
      fontFamily: 'Nunito',
      fontSize: '1.5vh',
      color:'black',
      fontWeight:'bolder',
      cursor:'pointer',
      marginRight:'2vh',
      marginLeft: '4vh',
      width:'20vh',
      background:'none',
      '&:hover':{
        backgroundColor:'black',
        color:'white'
      }
    },
    drawerMenuItems:{
      fontFamily: 'Nunito',
      fontSize: '1.5vh',
      color:'black',
      fontWeight:'bolder',
      cursor:'pointer',
      marginRight:'2vh',
      marginLeft: '2vh',
      width:'20vh',
      background:'none',
      textDecoration:'none',
      '&:hover':{
        backgroundColor:'black',
        color:'white'
      }
    },
    drawerOpenedMenuItems:{
      fontFamily: 'Nunito',
      fontSize: '1.5vh',
      color:'black',
      fontWeight:'bolder',
      cursor:'pointer',
      marginRight:'2vh',
      marginLeft: '2vh',
      width:'25vh',
      background:'none',
      textDecoration:'none',
      '&:hover':{
        backgroundColor:'black',
        color:'white'
      }
    },
    applyButton:{
      fontFamily: 'Nunito',
      fontSize: '1.5vh',
      color:'white',
      fontWeight:'bolder',
      cursor:'pointer',
      marginRight:'2vh',
      marginLeft: '2vh',
      width:'20vh',
      background:'SlateBlue',
      '&:hover':{
        backgroundColor:'mediumpurple',
        color:'white'
      }
  },
    logo: {
        fontFamily: "Work Sans, sans-serif",
        fontWeight: 600,
        color: "#FFFEFE",
        textAlign: "left",
    },
    menuButton: {
      float:'right'
      
    },
    menuListItems:{
      fontFamily: 'Nunito',
      fontSize: '1.5vh',
      color:'black',
      fontWeight:'bolder',
      cursor:'pointer',
      marginRight:'2vh',
      marginLeft: '2vh',
      width:'25vh',
      background:'none',
      textDecoration:'none',
      '&:hover':{
        backgroundColor:'black',
        color:'white'
      }
    },
    drawer:{
      maxHeight:'10vh'
    },
  }));

  const openForm = () => {
    const url = 'https://docs.google.com/forms/d/e/1FAIpQLSe718h6nr5LN2p2vkoxp0qI6MbMMNpw-C8OZIW9aH-MikzfCA/viewform?usp=sf_link';
    window.open(url, '_blank');
  }
  
  export default function Navbar(props) {
    const { header, logo, menuButton, toolbar, drawerContainer } = useStyles();
    const classes = useStyles();
    const [state, setState] = useState({
      mobileView: false,
      drawerOpen: false,
    });
  
    const { mobileView, drawerOpen } = state;

    const [anchorEl, setAnchorEl] =useState(null);
    const open = anchorEl;
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
      handleDrawerClose();
    };

    useEffect(() => {
      const setResponsiveness = () => {
        return window.innerWidth < 1250
          ? setState((prevState) => ({ ...prevState, mobileView: true }))
          : setState((prevState) => ({ ...prevState, mobileView: false }));
      };
  
      setResponsiveness();
  
      window.addEventListener("resize", () => setResponsiveness());
    }, []);
  
    const displayDesktop = () => {
      return (
        <Toolbar className={toolbar} >
          {getMenuButtons()}
        </Toolbar>
      );
    };
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>{
      setState((prevState) => ({ ...prevState, drawerOpen: false }));
    }

    const displayMobile = () => {
      return (
        <Toolbar className={toolbar}>
          <IconButton
            {...{
              edge: "start",
              color: "inherit",
              "aria-label": "menu",
              "aria-haspopup": "true",
              onClick: handleDrawerOpen,
            }}
          >
            <MenuIcon />
          </IconButton>
  
          <Drawer className={classes.drawer}
            {...{
              anchor: "left",
              open: drawerOpen,
              onClose: handleDrawerClose,
            }}
          >
            <div className={drawerContainer}>{getDrawerChoices()}</div>
          </Drawer>
          </Toolbar>
      );
    };
  
    const getDrawerChoices = () => {
        return getDrawerMenuItems();
    };

    const getDrawerMenuItems = () =>{
        return (<div className={classes.navBar}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                className={classes.menuButton}
                onClick={handleDrawerClose}
              >
                <CloseIcon />
              </IconButton>
            <MenuItem>
                <Scroll to="offerings" smooth={true}>
                    <Chip label="Tanfolyamok" className={classes.drawerMenuItems} onClick={() => handleDrawerClose()}/>
                </Scroll>
            </MenuItem>
            <Divider/>
            <MenuItem>
                <Scroll to="prices" smooth={true}>
                    <Chip label="Árak" className={classes.drawerMenuItems} onClick={() => handleDrawerClose()}/>
                </Scroll>
            </MenuItem>
            <Divider/>
            <MenuItem>
                <Scroll to="teachers" smooth={true}>
                    <Chip label="Oktatók" className={classes.drawerMenuItems} onClick={() => handleDrawerClose()}/>
                </Scroll>
            </MenuItem>
            <Divider/>
            <MenuItem>
                <Scroll to="discounts" smooth={true}>
                    <Chip label="Kedvezmények" className={classes.drawerMenuItems} onClick={() => handleDrawerClose()}/>
                </Scroll>
            </MenuItem>
            <Divider/>
            <MenuItem>
                <Scroll to="contacts" smooth={true}>
                    <Chip label="Elérhetőségek" className={classes.drawerMenuItems} onClick={() => handleDrawerClose()}/>
                </Scroll>
            </MenuItem>
            <Divider/>
            <Scroll>
              <Chip label="Tudnivalók" aria-controls="fade-menu" onClick={handleClick} className={classes.drawerButton}></Chip>
            </Scroll>
              <Menu
                id="fade-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
                
              >
                <MenuItem onClick={handleClose}>                  
                  <a className={classes.drawerOpenedMenuItems} href={process.env.PUBLIC_URL + 'assets/documents/napi.pdf'} target="_blank" rel="noreferrer">Napi első indulás előtti teendők</a>
                </MenuItem>
                <MenuItem onClick={handleClose} >
                  <a className={classes.drawerOpenedMenuItems} href={process.env.PUBLIC_URL + 'assets/documents/tanuloi_tajekoztato.pdf'} target="_blank" rel="noreferrer">Tanulói tájékoztató</a>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <a className={classes.drawerOpenedMenuItems} href={process.env.PUBLIC_URL + 'assets/documents/adatkezelesi.pdf'} target="_blank" rel="noreferrer">Adatkezelési nyilatkozat</a>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <a className={classes.drawerOpenedMenuItems} href="https://vizsgakozpont.hu/ako_vsm" target="_blank">VSM és ÁKÓ </a>
                </MenuItem>
              </Menu>
              <Divider/>
            <MenuItem>
                <Chip label="Jelentkezés" className={classes.applyButton} onClick={() => {
                  openForm();
                  handleDrawerClose();
                  }
                }/>
            </MenuItem>
        </div>);
    }

    const getMenuButtons = () => {
      return (
            <Toolbar>
                <Scroll to="offerings" smooth={true}>
                    <Chip label="Tanfolyamok" className={classes.menuItems}/>
                </Scroll>
                <Scroll to="prices" smooth={true}>
                    <Chip label="Árak" className={classes.menuItems}/>
                </Scroll>
                <Scroll to="teachers" smooth={true}>
                    <Chip label="Oktatók" className={classes.menuItems}/>
                </Scroll>
                <Scroll to="discounts" smooth={true}>
                    <Chip label="Kedvezmények" className={classes.menuItems}/>
                </Scroll>
                <Scroll to="contacts" smooth={true}>
                    <Chip label="Elérhetőségek" className={classes.menuItems}/>
                </Scroll>
                <Chip label="Tudnivalók" aria-controls="fade-menu" onClick={handleClick} className={classes.menuItems}></Chip>
                <Menu
                  id="fade-menu" 
                  anchorEl={anchorEl}
                  keepMounted
                  open={open}
                  onClose={handleClose}
                  TransitionComponent={Fade}
                >
                  <MenuItem onClick={handleClose}>
                    <a className={classes.menuListItems} href={process.env.PUBLIC_URL + 'assets/documents/napi.pdf'} target="_blank">Napi első indulás előtti teendők</a>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <a className={classes.menuListItems} href={process.env.PUBLIC_URL + 'assets/documents/tanuloi_tajekoztato.pdf'} target="_blank">Tanulói tájékoztató</a>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <a className={classes.menuListItems} href={process.env.PUBLIC_URL + 'assets/documents/adatkezelesi.pdf'} target="_blank">Adatkezelési nyilatkozat</a>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                  <a className={classes.drawerOpenedMenuItems} href="https://vizsgakozpont.hu/ako_vsm" target="_blank">VSM és ÁKÓ </a>
                </MenuItem>
                </Menu>
                <Chip label="Jelentkezés" className={classes.applyButton} onClick={() => openForm()}/>
            </Toolbar>
      )};
  
    return (
      <header>
        <HideOnScroll>
          <AppBar className={header} className={classes.appBar} position='fixed'>
            {mobileView ? displayMobile() : displayDesktop()}
          </AppBar>
        </HideOnScroll>
      </header>
    );
  }