import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Divider } from '@material-ui/core';
import ScrollAnimation from 'react-animate-on-scroll';


const useStyles = makeStyles({
    root: {
      width: '50vh',
      height: '60vh',
      background: 'none'
    },
    cardTitle:{
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: '3vh',
        textAlign:'center',
    },
    cardContent:{
      fontFamily: 'Nunito',
      fontSize: '1.0vh',
      fontWeight: 'bold',
      color: '#fff',
      
    },
  });
function PriceCard({title, priceTable, animationDirection}) {
    const classes = useStyles();    
    return (
        <ScrollAnimation animateIn={animationDirection} animateOnce={true}>
            <Card className={classes.root}>
                <Typography 
                    gutterBottom 
                    variant="h5" 
                    component="h1"
                    className={classes.cardTitle}>
                    {title}
                </Typography>
                <Divider/>
                <CardContent>
                    {priceTable}
                </CardContent>
            </Card>
        </ScrollAnimation>
    );
}

export default PriceCard;
