import { Card, CardMedia } from '@material-ui/core';
import React, { useState } from 'react'
import FacebookFeed from './FacebookFeed';

function FacebookContainer() {
    return (
        <div style={{minHeight: '60vh'}} id="news">
            <h1 style={{fontFamily:'nunito', fontSize:'4vh', textAlign:'center'}}>Aktuális híreinket a Facebook oldalunkon olvashatjátok! <br/>Like-oljatok minket!</h1>
            <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                <FacebookFeed/>
            </div>
        </div>
    )
}

export default FacebookContainer;
;