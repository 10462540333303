import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { CardActions, CardHeader, Divider,CardMedia } from '@material-ui/core';
import ScrollAnimation from 'react-animate-on-scroll';


const useStyles = makeStyles({
    root: {
      width: '50vh',
      height: '70vh',
      background: 'none'
    },
    cardTitle:{
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: '3vh',
        textAlign:'center',
    },
    cardContent:{
      fontFamily: 'Nunito',
      fontSize:'3vh'
    },
    cardAction:{
        display: 'flex',
        justifyContent: 'center',
        alignItems:'center',
        margin:'5vh'
    },
    media: {
        height: '25vh',
    },
  });
function DiscountCard({title, priceTable, animationDirection, actions, imagePath}) {
    const classes = useStyles();    
    return (
        <ScrollAnimation animateIn={animationDirection} animateOnce={true}>
            <Card className={classes.root}>
            <CardMedia
                  className={classes.media}
                  image={imagePath}
            />
                <Typography 
                    gutterBottom 
                    variant="h5" 
                    component="h1"
                    className={classes.cardTitle}>
                    {title}
                </Typography>
                <Divider/>
                <CardContent>
                    <Typography 
                        gutterBottom 
                        variant="h5" 
                        component="h1"
                        className={classes.cardContent}>
                        {priceTable}
                    </Typography>
                </CardContent>
                <CardActions className={classes.cardAction}>
                    {actions}
                </CardActions>
            </Card>
        </ScrollAnimation>
    );
}

export default DiscountCard;
