import React from 'react';
import Footer from './components/Footer';
import MainPage from './components/MainPage';


function App() {

  return (
      <>
          <MainPage/>
          <Footer/>
      </>
  );
}

export default App;
