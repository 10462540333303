import React from 'react'
import { makeStyles } from '@material-ui/core'
import LocationInfo from './LocationInfo';
import ScrollAnimation from 'react-animate-on-scroll';
import FacebookFeed from './FacebookFeed';

const useStyles = makeStyles((theme) => ({
    root:{
        minHeight:"70vh",
        margin:'5vh 0 0 0',
        backgroundSize:'100% 100vh'
    },
    sectionTitle:{
        fontSize: '6vh',
        fontFamily: 'Nunito',
        fontWeight:'bolder',
        textAlign:'center'
    },
    feed:{
        display:'flex',
        justifyContent:'center',
        marginBottom:'2vh'
    }
}));

function Contact() {
    const classes = useStyles();
    return (
        <div id="contacts" className={classes.root}>
            <ScrollAnimation animateIn={'flipInY'} animateOnce={true}><h1 className={classes.sectionTitle}>Vedd fel velünk a kapcsolatot!</h1></ScrollAnimation>
            <LocationInfo/>
        </div>
    )
}

export default Contact;
