import React, { useState } from 'react'
import { Button, CardActions, makeStyles } from '@material-ui/core'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import ScrollAnimation from 'react-animate-on-scroll';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import Dialog from '@material-ui/core/Dialog';
import ImageGallery from 'react-image-gallery';
import "./Teachers.css";

const useStyles = makeStyles((theme) => ({
    root:{
        minHeight:"100vh",
    },
    sectionTitle:{
        fontSize: '6vh',
        fontFamily: 'Nunito',
        fontWeight:'bolder',
        textAlign:'center',
        margin:'6vh 0vh 0vh 0vh',
        color:'black'
    },
    media: {
        height: '100vh',
    },
    cards: {
        [theme.breakpoints.down('md')]:{
            flexDirection:'column'
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems:'center',
        gap: '5vh',
        margin:'10vh 2vh 0vh 2vh',
        flexWrap:'wrap'
    },
    cardTitle:{
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: '3.5vh',
    },
    gif:{
        margin: '8vh',
        display: 'block',
        width:'42vh',
        height:'30vh',
        marginLeft: 'auto',
        marginRight: 'auto',
        borderRadius:'2vh'
    },
    gifTitle:{
        fontFamily: 'Nunito',
        fontWeight: 'bolder',
        fontSize: '4vh',
        textAlign:'center'
    },
    learnFromPros:{
        marginTop:'4vh'
    },
    cardContent:{
        fontSize:'2.5vh',
        color:'black'
    },
    carMedia: {
        minHeight:'40vh',
        minWidth:'30vh'
    }

}));



function Teachers() {
    const classes = useStyles();
    const [showModal, setShowModal] = useState(false);
    const [selectedTeacher, setSelectedTeacher] = useState(null);
    const teachers=[
        {
            name: 'Farkas Dömötör',
            car: 'Toyota ',
            profilePicture: 'teachers/farkas_domotor-min.jpg',
            profilePictureThumbnail: 'teachers/thumbnail/farkas_domotor-min.jpg',
            carDescription: 'Ha őt választod autóvezető oktatódnak akkor egy Toyota autót fogsz vezetni!'
        },
        {
            name: 'Györfi Szilárd',
            car: 'Toyota',
            profilePicture: 'teachers/gyorfi_szilard-min.jpg',
            profilePictureThumbnail: 'teachers/thumbnail/gyorfi_szilard-min.jpg',
            carPicture: 'cars/gyorfi_szilard-min.jpg',
            carPictureThumbnail: 'cars/thumbnail/gyorfi_szilard-min.jpg',
            carDescription: 'Ha őt választod autóvezető oktatódnak akkor egy Toyota autót fogsz vezetni!'
        },
        {
            name: 'Herczog Gábor',
            car: 'Renault',
            profilePicture: 'teachers/herczog_gabor-min.jfif',
            profilePictureThumbnail: 'teachers/thumbnail/herczog_gabor-min.jpg',
            carPicture: 'cars/herczog_gabor-min.jfif',
            carPictureThumbnail: 'cars/thumbnail/herczog_gabor-min.jpg',
            carDescription: 'Ha őt választod autóvezető oktatódnak akkor egy Renault autót fogsz vezetni!'
        },
        {
            name: 'Ocskó Zoltán',
            car: 'Toyota',
            profilePicture: 'teachers/ocsko_zoltan-min.jfif',
            profilePictureThumbnail: 'teachers/thumbnail/ocsko_zoltan-min.jpg',
            carPicture: 'cars/ocsko_zoltan-min.jfif',
            carPictureThumbnail: 'cars/thumbnail/ocsko_zoltan-min.jpg',
            secondCarPicture:'cars/okcso_zoltan_2-min.jpg',
            secondCarPictureThumbnail: 'cars/thumbnail/okcso_zoltan_2-min.jpg',
            carDescription: 'Ha őt választod autóvezető oktatódnak akkor egy Toyota autót fogsz vezetni!'
        },
        {
            name: 'Pánczél Nándor',
            car: 'Opel',
            profilePicture: 'teachers/panczel_nandor-min.jfif',
            profilePictureThumbnail: 'teachers/thumbnail/panczel_nandor-min.jpg',
            carPicture: 'cars/panczel_nandor-min.jfif',
            carPictureThumbnail: 'cars/thumbnail/panczel_nandor-min.jpg',
            carDescription: 'Ha őt választod autóvezető oktatódnak akkor egy Opel autót fogsz vezetni!'
        },
        {
            name: 'Szücs Gábor',
            car: 'Suzuki',
            profilePicture: 'teachers/szucs_gabor-min.jfif',
            profilePictureThumbnail: 'teachers/thumbnail/szucs_gabor-min.jpg',
            carPicture: 'cars/szucs_gabor-min.jfif',
            carPictureThumbnail: 'cars/thumbnail/szucs_gabor-min.jpg',
            carDescription: 'Ha őt választod autóvezető oktatódnak akkor egy Suzuki autót fogsz vezetni!'
        },
        {
            name: 'Szücs Szabolcs',
            car: 'Suzuki',
            profilePicture: 'teachers/szucs_szabolcs-min.jfif',
            profilePictureThumbnail: 'teachers/thumbnail/szucs_szabolcs-min.jpg',
            carPicture: 'cars/szucs_szabolcs-min.jfif',
            carPictureThumbnail: 'cars/thumbnail/szucs_szabolcs-min.jpg',
            carDescription: 'Ha őt választod autóvezető oktatódnak akkor egy Suzuki autót fogsz vezetni!'
        },
        {
            name: 'Szüts Attila',
            car: 'Toyota',
            profilePicture: 'teachers/szuts_attila-min.jpg',
            profilePictureThumbnail: 'teachers/thumbnail/szuts_attila-min.jpg',
            carPicture: 'cars/szuts_attila-min.jpg',
            carPictureThumbnail: 'cars/thumbnail/szuts_attila-min.jpg',
            carDescription: 'Ha őt választod autóvezető oktatódnak akkor egy Toyota autót fogsz vezetni!'
        },
        {
            name: 'Vig Sándor',
            car: 'Citroen ',
            profilePicture: 'teachers/vig_sandor-min.jfif',
            profilePictureThumbnail: 'teachers/thumbnail/vig_sandor-min.jpg',
            carPicture: 'cars/vig_sandor-min.jfif',
            carPictureThumbnail: 'cars/thumbnail/vig_sandor-min.jpg',
            carDescription: 'Ha őt választod autóvezető oktatódnak akkor egy Citroen autót fogsz vezetni!'
        },
    ];
    
    const handleClose = () => {
        setShowModal(false);
        setSelectedTeacher(null);
    };

    const handleOpen = (teacher) => {
        setSelectedTeacher(teacher);
        setShowModal(true);
    }

    const getImages = () => {
        if (selectedTeacher.name === 'Farkas Dömötör'){
            return [
                {
                    original: process.env.PUBLIC_URL + 'assets/' + selectedTeacher.profilePicture,
                    thumbnail: process.env.PUBLIC_URL + 'assets/' +  selectedTeacher.profilePictureThumbnail
                }
            ]
        }
        if (selectedTeacher.name !== 'Ocskó Zoltán'){
            return [
                {
                    original: process.env.PUBLIC_URL + 'assets/' + selectedTeacher.profilePicture,
                    thumbnail: process.env.PUBLIC_URL + 'assets/' +  selectedTeacher.profilePictureThumbnail
                },
                {
                    original: process.env.PUBLIC_URL + 'assets/' +  selectedTeacher.carPicture,
                    thumbnail: process.env.PUBLIC_URL + 'assets/' +  selectedTeacher.carPictureThumbnail
                }
            ]
        }
        return [
            {
                original: process.env.PUBLIC_URL + 'assets/' + selectedTeacher.profilePicture,
                thumbnail: process.env.PUBLIC_URL + 'assets/' +  selectedTeacher.profilePictureThumbnail
            },
            {
                original: process.env.PUBLIC_URL + 'assets/' +  selectedTeacher.carPicture,
                thumbnail: process.env.PUBLIC_URL + 'assets/' +  selectedTeacher.carPictureThumbnail
            },
            {
                original: process.env.PUBLIC_URL + 'assets/' +  selectedTeacher.secondCarPicture,
                thumbnail: process.env.PUBLIC_URL + 'assets/' +  selectedTeacher.secondCarPictureThumbnail
            }
        ]
    }

    const teachersView = teachers.map(teacher => {
        return (
            <ScrollAnimation animateIn={'fadeIn'} animateOnce={true} key={teacher.name}>
                <Card key={teacher.name}>
                    <CardMedia
                    className={classes.media}
                    image={process.env.PUBLIC_URL + 'assets/' + teacher.profilePicture}
                    title={teacher.name + ' oktató'}
                    />
                    <CardContent>
                    <Typography 
                        gutterBottom 
                        variant="h5" 
                        component="h1"
                        className={classes.cardTitle}>
                        {teacher.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" className={classes.cardContent}>
                        Ha őt választod autóvezető oktatódnak akkor egy {teacher.car}-t fogsz vezetni!<br/>
                    </Typography>
                    </CardContent>
                    <CardActions>
                        <Button onClick={() => handleOpen(teacher)}>
                            <span>
                                <ZoomInIcon style={{fontSize:'5vh'}}/>
                            </span>
                        </Button>
                    </CardActions>
                </Card>
            </ScrollAnimation>    
        )
    });
    return (
        <div id="teachers" className={classes.root}>
            <h1 className={classes.sectionTitle}>
                Oktatók
            </h1>
            <div className={classes.learnFromPros}>
                <ScrollAnimation animateIn={'bounceInLeft'} animateOnce={true} >
                    <h1 className={classes.gifTitle}>Tanulj profiktól!</h1>
                    <img className={classes.gif} src={process.env.PUBLIC_URL + 'assets/car.webp'}/>
                </ScrollAnimation>    
            </div>
            <ScrollAnimation animateIn={'fadeIn'} animateOnce={false}>
                <Dialog
                    open={showModal}
                    onClose={handleClose}
                    className={classes.carCard}
                >
                    {selectedTeacher !== null ? <ImageGallery items={getImages()}/>: null}
                </Dialog>
            </ScrollAnimation>    
            <div className={classes.cards}>
                {teachersView}
            </div>

        </div>
    )
}

export default Teachers;
