import React from 'react';
import Button from '@material-ui/core/Button';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import { Card, CardContent, CardHeader, CardMedia, makeStyles, Snackbar } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import MuiAlert from '@material-ui/lab/Alert';
import emailjs from 'emailjs-com';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

class WriteToUs extends React.Component {
    
    state = {
        email: '',
        message:'',
        phoneNumber:'',
        name:'',
        open: false,
    }

    handleChangeEmail = (event) => {
        const email = event.target.value;
        this.setState({ email });
    }

    handleChangeMessage = (event) => {
        const message = event.target.value;
        this.setState({ message });
    }

    handleChangeName = (event) => {
        const name = event.target.value;
        this.setState({ name });
    }

    handleChangePhoneNumber = (event) => {
        const phoneNumber = event.target.value;
        this.setState({ phoneNumber });
    }

    sendMail = (e) => {
        e.preventDefault();
        const {email, message, name, phoneNumber} = this.state;
        emailjs.send('service_11rxtms', 'template_adpxhr4', {email, message, name, phoneNumber}, 'user_HRH8hl7VKCeZ8XgmslSoQ')
                .then((result) => this.setState({open: true}), 
                      (error) => {}
                    );
    }

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({open: false});
    }

    render() {
        const { email, message, phoneNumber, name } = this.state;
        return (
            <>
                <Card style={{background:'none'}}>
                    <CardContent>
                    <h1 style={{fontFamily:'nunito', fontSize:'3vh', fontWeight:'bold'}}>Kérdésed van? Írj nekünk!</h1>
                        <ValidatorForm
                        ref="form"
                        onSubmit={this.sendMail}
                            >
                            <TextValidator
                                style={{fontFamily:'nunito', minWidth:'20vh'}}
                                label="Név"
                                onChange={this.handleChangeName}
                                name="name"
                                value={name}
                                validators={['required']}
                                errorMessages={['Név megadása szükséges!', ]}
                            />
                            <TextValidator
                                style={{fontFamily:'nunito', minWidth:'20vh'}}
                                className="validateform"
                                label="Telefonszám"
                                onChange={this.handleChangePhoneNumber}
                                name="phoneNumber"
                                value={phoneNumber}
                            />
                            <TextValidator
                                style={{fontFamily:'nunito', minWidth:'20vh'}}
                                label="Email cím"
                                onChange={this.handleChangeEmail}
                                name="email"
                                value={email}
                                validators={['required', 'isEmail']}
                                errorMessages={['Email cím megadása szükséges!', 'Nem megfelelő az email cím formátuma!']}
                            />
                            <TextValidator
                                style={{fontFamily:'nunito', minWidth:'40vh'}}
                                label="Üzenet"
                                onChange={this.handleChangeMessage}
                                name="message"
                                value={message}
                                validators={['required']}
                                errorMessages={['Üzenet megadása szükséges!']}
                                multiline
                                rows={5}
                            />
                            <br/>
                            <Button type="submit" variant="contained" style={{display:'flex', justifyContent:'right', background:'none', fontFamily:'nunito', fontSize:'1.5vh', fontWeight:'bold'}}>
                                <SendIcon style={{fontSize:'2vh', marginRight:'2vh'}}/>
                                <span>Küldés</span>
                            </Button>
                        </ValidatorForm>
                    </CardContent>
                </Card>
                <Snackbar open={this.state.open} autoHideDuration={6000} onClose={this.handleClose}>
                    <Alert onClose={this.handleClose} severity="success">
                    Üzenet sikeresen elküldve! Hamarosan felvesszük veled a kapcsolatot!
                    </Alert>
                </Snackbar>
            </>
        );
    }
}

export default WriteToUs;
