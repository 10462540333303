import React, {useEffect, useRef } from 'react'
import { Card, CardContent, makeStyles, Typography } from '@material-ui/core'
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import HomeIcon from '@material-ui/icons/Home';
import ScheduleIcon from '@material-ui/icons/Schedule';
import CallIcon from '@material-ui/icons/Call';
import EmailIcon from '@material-ui/icons/Email';
import ScrollAnimation from 'react-animate-on-scroll';
import Apply from './Apply';
import WriteToUs from './WriteToUs';

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
mapboxgl.accessToken = 'pk.eyJ1IjoiaWxsZXN0aWJvcjk1IiwiYSI6ImNrYXc5cWlxajAyOXkycm12bmF1bWpidmMifQ.nTFjJ5-4W3cfIeYwZVom1g';


const useStyles = makeStyles((theme) => ({
    map:{
        width:'50vh',
        height:'30vh',
        borderRadius:'2vh',
        [theme.breakpoints.down('md')]:{
            flexDirection:'column',
            justifyContent:'space-between'
        },
    },
    location:{
        [theme.breakpoints.down('md')]:{
            flexDirection:'column',
            justifyContent:'space-between'
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems:'center',
        gap: '10vh',
    },
    cardTitle:{
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: '4vh',
    },
    cardContent:{
        fontSize:'3vh',
        fontFamily: 'Nunito',
    },
    infoCards:{
        width:'50vh',
        height:'120vh',
        [theme.breakpoints.down('md')]:{
            flexDirection:'column',
        },

    },
    listElements:{
        fontSize:'2.5vh',
        fontFamily: 'Nunito',
        marginLeft:'10vh'
    },
    additionalInfo:{
        fontFamily: 'Nunito',
        fontSize:'2.0vh',
        fontWeight:'bold'
    },
    apply:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        marginBottom:'10vh',
        background:'none'
    },
    contactUs:{
        display:'inline-block'
    }
}));

function LocationInfo() {
    const classes = useStyles();
    const mapContainerRef = useRef(null);

    // initialize map when component mounts
    useEffect(() => {
        const map = new mapboxgl.Map({
        container: mapContainerRef.current,
            // See style options here: https://docs.mapbox.com/api/maps/#styles
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [18.416148284623805, 47.18949876693429],
            zoom: 15,
        });
    
        // add navigation control (the +/- zoom buttons)
        map.addControl(new mapboxgl.NavigationControl(), 'bottom-right');

        // clean up on unmount
        return () => map.remove();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return <div className={classes.location}>
        <div className={classes.infoCards}>
            <ScrollAnimation animateIn={'bounceInLeft'} animateOnce={true} >
                <Card style={{background:'none'}}>
                    <CardContent >
                    <Typography className={classes.cardContent}
                            gutterBottom 
                            variant="h5" 
                            component="h1">
                        <HomeIcon style={{fontSize:'4vh', marginRight:'2vh'}}/>
                        <span>Cím : 8000, Székesfehérvár Budai út 18.</span>                
                    </Typography>
                    </CardContent>
                </Card>
            </ScrollAnimation>
            <ScrollAnimation animateIn={'bounceInLeft'} animateOnce={true} >
                <Card style={{background:'none'}}>
                    <CardContent >
                    <Typography className={classes.cardContent}
                            gutterBottom 
                            variant="h5" 
                            component="h1">
                        <ScheduleIcon style={{fontSize:'4vh', marginRight:'2vh'}}/>
                        <span>
                            Nyitvatartás: 
                                <li className={classes.listElements}>Csütörtök: 08.00-16.00</li>
                        </span>                 
                    </Typography>
                    </CardContent>
                </Card>
            </ScrollAnimation>
            <ScrollAnimation animateIn={'bounceInLeft'} animateOnce={true} >
                <Card style={{background:'none'}}>
                    <CardContent>
                    <Typography className={classes.cardContent}
                            gutterBottom 
                            variant="h5" 
                            component="h1">
                        <CallIcon style={{fontSize:'4vh', marginRight:'2vh'}}/>
                        <span>
                            Telefonos elérhetőségek: 
                                <li className={classes.listElements}>+36 70/850 99 80</li>
                                <li className={classes.listElements}>+36 70/850 99 82</li>
                                <p className={classes.additionalInfo}>Munkaidőn belül bármikor hívható, kollégánk szívesen áll a Te rendelkezésedre.</p>
                        </span>                 
                    </Typography>
                    </CardContent>
                </Card>
            </ScrollAnimation>
            <ScrollAnimation animateIn={'bounceInLeft'} animateOnce={true} >
                <Card style={{background:'none'}}>
                    <CardContent>
                    <Typography className={classes.cardContent}
                            gutterBottom 
                            variant="h5" 
                            component="h1">
                        <EmailIcon style={{fontSize:'4vh', marginRight:'2vh'}}/>
                        <span>Email: autosiskolazebra@gmail.com</span>                
                    </Typography>
                    </CardContent>
                </Card>
            </ScrollAnimation>
            <ScrollAnimation animateIn={'bounceInLeft'} animateOnce={true}>
            <Card className={classes.apply}>
                    <CardContent>
                        <Typography className={classes.cardContent}
                                gutterBottom 
                                variant="h5" 
                                component="h1">
                            <Apply/>            
                        </Typography>
                    </CardContent>
                </Card>
            </ScrollAnimation>        
        </div>
        <div className={classes.infoCards}>
            <ScrollAnimation style={{marginBottom:'2vh'}} animateIn={'bounceInRight'} animateOnce={true} >
                <div className={classes.map}ref={mapContainerRef} />
            </ScrollAnimation>
            <ScrollAnimation animateIn={'bounceInRight'} animateOnce={true} >
                <WriteToUs/>
            </ScrollAnimation>
        </div>
    </div>;
}

export default LocationInfo;
