import React from 'react'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import PaymentIcon from '@material-ui/icons/Payment';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
   root:{
       fontSize:'5vh'
   }
}));


function ClassRoomPriceTable({schedule, hour, price}) {
    const classes = useStyles();
    return (
        <List>
        <ListItem>
          <ListItemAvatar>
              <EventAvailableIcon style={{fontSize:'8vh'}}/>
          </ListItemAvatar>
          <ListItemText primary={"Oktatás ideje"} secondary={schedule} />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
              <QueryBuilderIcon style={{fontSize:'8vh'}}/>
          </ListItemAvatar>
          <ListItemText primary={"Óraszám"} secondary={hour}/>
        </ListItem>
        <ListItem>
          <ListItemAvatar>
              <PaymentIcon style={{fontSize:'8vh'}}/>
          </ListItemAvatar>
          <ListItemText primary={"Képzés ára"} secondary={<b>{price}</b>} />
        </ListItem>
      </List>
    )
}

export default ClassRoomPriceTable;