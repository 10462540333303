import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import ScrollAnimation from 'react-animate-on-scroll';

const useStyles = makeStyles({
  root: {
    width: '40vh',
    height: '130vh',
    background: 'none',
  },
  media: {
    height: '25vh',
  },
  cardTitle:{
      fontFamily: 'Nunito',
      fontWeight: 'bold',
      fontSize: '3vh',
  },
  cardContent:{
    fontFamily: 'Nunito',
    fontSize: '3vh',
    color: '#000'
  },
});

export default function OfferingCard({cardTitle, desc, imagePath, animationDirection}) {
  const classes = useStyles();

  return (
      <ScrollAnimation animateIn={animationDirection} animateOnce={true} >
          <Card className={classes.root}>
                  <CardMedia
                  className={classes.media}
                  image={imagePath}
                  title={cardTitle}
                  />
                  <CardContent>
                  <Typography 
                      gutterBottom 
                      variant="h5" 
                      component="h1"
                      className={classes.cardTitle}>
                      {cardTitle}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p" className={classes.cardContent}>
                    {desc}
                  </Typography>
                  </CardContent>
          </Card>
        </ScrollAnimation>
  );
}