import { Card, CardContent, makeStyles, Typography } from '@material-ui/core';
import React from 'react'
import OfferingCard from './OfferingCard';
import ElearningDescription from './ElearningDescription';
import ClassBasedDescription from './ClassBasedDescription';
import ScrollAnimation from 'react-animate-on-scroll';

const useStyles = makeStyles((theme) => ({
    root:{
        minHeight:"100vh",
        backgroundSize:'100% 100vh'
    },
    cards: {
        [theme.breakpoints.down('md')]:{
            flexDirection:'column'
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems:'center',
        gap: '10vh',
    },
    cardContent:{
        fontSize:'3vh',
        fontFamily: 'Nunito',
    },
    sectionTitle:{
        fontSize: '6vh',
        fontFamily: 'Nunito',
        fontWeight:'bolder',
        textAlign:'center'
    },
    cardTitle:{
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: '4vh',
    },
    requirements:{
        display: 'flex',
        justifyContent: 'center',
        alignItems:'center',
        fontSize:'3vh',
        fontFamily: 'Nunito',
        fontWeight:'bolder',
        margin:'2vh',
    },
    requirementsCard:{
        background:'none'
    },
    media: {
        height: '25vh',
    },
    cardDescription:{
        fontSize:'5vh'
    }
}));

function Offerings() {
    const classes = useStyles();

    return (
        <div className={classes.root} id="offerings">
            <ScrollAnimation animateIn={'flipInY'} animateOnce={true}><h1 className={classes.sectionTitle}>Elméleti Tanfolyamok</h1></ScrollAnimation>
            <div className={classes.cards}>
                <OfferingCard cardTitle="E-learning" animationDirection="bounceInLeft" desc={<ElearningDescription></ElearningDescription>} imagePath={process.env.PUBLIC_URL + 'assets/e-learning.svg'} />
                <OfferingCard cardTitle="Tantermi oktatás" animationDirection="bounceInRight" desc={<ClassBasedDescription></ClassBasedDescription>} imagePath={process.env.PUBLIC_URL + 'assets/classroom.svg'} />
            </div>
            <div className={classes.requirements}>
                <ScrollAnimation animateIn={'bounceInUp'} animateOnce={true} >
                    <Card className={classes.requirementsCard}>
                        <CardContent>
                        <Typography 
                            gutterBottom 
                            variant="h5" 
                            component="h1"
                            className={classes.cardTitle}
                            >
                            Képzés feltételei
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p" className={classes.cardContent}>
                            <ul>
                                <li>betöltött 16,5 életév</li>
                                <li>I. csoportú orvosi alkalmassági igazolás</li>
                                <li>minimum alapfokú végzettséget igazoló bizonyítvány</li>
                            </ul>
                        </Typography>
                        </CardContent>
                    </Card>
                </ScrollAnimation>
            </div>
        </div>
    )
}

export default Offerings;
