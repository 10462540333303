import React from 'react'

function ClassBasedDescription() {
    return (
        <div>
           A tanfolyam 28 órából áll, ezt az óraszámot 7 alkalomra bontottuk, melynek időpontjait a résztvevők igényeihez igazítunk.
            <br/>
            <br/>
           A tananyag elsajátítását az oktató magyarázatán kívül, egy interaktív oktatóprogram is segíti.
            <br/>
            <br/>
           Igény szerint hozzáférést biztosítunk egy online gyakorló tesztprogramhoz, amellyel maximális felkészülést szerezhetsz a sikeres KRESZ vizsgához! 
        </div>
    )
}

export default ClassBasedDescription;
