import React from 'react'
import { Button, makeStyles} from '@material-ui/core'
import PanToolIcon from '@material-ui/icons/PanTool';

const useStyles = makeStyles((theme) => ({
    applyButton:{
        fontFamily:'Nunito',
        fontWeight:'bolder',
        fontSize:'1.5vh',
        background:'none',
        display:'flex',
        alignItems:'center',
        justifyContent:'center'

    },
}));
const openForm = () => {
    const url = 'https://docs.google.com/forms/d/e/1FAIpQLSe718h6nr5LN2p2vkoxp0qI6MbMMNpw-C8OZIW9aH-MikzfCA/viewform?usp=sf_link';
    window.open(url, '_blank');
}

function Apply() {
    const classes = useStyles();
    return (
        <div id="apply">
            <Button variant="contained" className={classes.applyButton} onClick={() => openForm()}>
                <PanToolIcon style={{fontSize:'2vh', marginRight:'2vh'}}/>
                <span>
                    Jelentkezz még ma!
                </span>
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSe718h6nr5LN2p2vkoxp0qI6MbMMNpw-C8OZIW9aH-MikzfCA/viewform?usp=sf_link" target="__blank"></a>
            </Button>
        </div>
    )
}

export default Apply;
